import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import regenarateReport from "../../assets/images/clarity_note-edit-line.svg";
import raiseQuery from "../../assets/images/carbon_query.svg";
import mapView from "../../assets/images/carbon_map.svg";
import archieve from "../../assets/images/archieve.svg";
import ReactGA from "react-ga4";
import "./styles.css";

export default function Dropdownmenu({
  anchorEl,
  tableData,
  handleQuery,
  handleArchieve,
  handleReportRegeneration,
  storeRefNo,
  addActive,
  storeRequestId,
  storeTableRowData,
  handleMapView,
  surveyDetails,
  dropDownOptions,
}) {
  const archiveButtonActiveColor = useSelector(
    (state) => state.filterTableComponentReducer.archiveButtonActiveColor,
  );

  // Memoize the listItems array
  const listItems = useMemo(
    () => [
      {
        list: "Map View",
        listImage: mapView,
        listFunction: handleMapView,
        index: 0,
      },
      {
        list: "Regenerate Report",
        listImage: regenarateReport,
        listFunction: handleReportRegeneration,
        index: 1,
      },
      {
        list: "Raise a Query",
        listImage: raiseQuery,
        listFunction: handleQuery,
        index: 2,
      },
      {
        list: "Archive",
        listImage: archieve,
        listFunction: handleArchieve,
        index: 3,
      },
    ],
    [handleMapView, handleReportRegeneration, handleQuery, handleArchieve],
  );

  if (anchorEl !== tableData) {
    return null;
  }

  return (
    <ul className="custom-dropdown" ref={dropDownOptions}>
      <li className="arrow-pointer"></li>
      {listItems.map((item) => {
        if (archiveButtonActiveColor && item.list === "Archive") {
          return null;
        }

        return (
          <li
            key={item.index}
            onClick={() => {
              ReactGA.event({
                category: "click",
                action: item.list.replace(/\s+/g, "_"),
              });
              item.listFunction(
                storeTableRowData,
                storeRefNo,
                storeRequestId,
                item.index,
                surveyDetails,
              );
            }}
            className={addActive === item.index ? "custom-menu-list" : ""}
          >
            <img src={item.listImage} alt={item.list} />
            <p className="custom-dropdown-content">{item.list}</p>
          </li>
        );
      })}
    </ul>
  );
}
