import React, { useEffect, useState, useRef } from "react";
import "./map-table.css";
import information from "../../assets/images/table/information.svg";
import Checkbox from "@mui/material/Checkbox";
import Bufferzone from "./Bufferzone";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
// Action
import { _getStorageValue } from "../../common/localStorage";
import { ACCESS_TOKEN, USER_ID } from "../../common/constants";
import { checkExistingRefId } from "../filter-component/filterfun";
// duplicate entry check pop up import
import DupEntryCheckPopUP from "../../PopUps/DupEntryCheck/";
import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import { useDispatch } from "react-redux";
// import disable select picker.
import { DISABLE_SELECTPICKER } from "../../action/actionConstants";
import { downloadShapeFile } from "../filter-component/filterfun";
import moment from "moment";
import ReactGA from "react-ga4";

const MapTable = ({
  props,
  mapPolygonData,
  setMapPolyData,
  setBufferZone,
  setSelectedData,
  setSelectedBufferData,
  storeCurrentlevel,
  storeTableHeaders,
  filter,
  storeDropdowncomponents,
}) => {
  const [result, setResult] = useState([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState([]);
  const [bufferResult, setBufferResult] = useState([]);
  const [selectedBufferResult, setSelectedBufferResult] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [bufferCheckAll, setBufferCheckAll] = useState(false);
  // to store the ref id
  const [storeRefId, setStoreRefId] = useState("");
  // duplicate ref id modal state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // check if ref id exists
  const [refIdExists, setRefIdExists] = useState("");
  // state for validation message
  const [errorMessage, setErrorMessage] = useState(false);
  // for duplicate entries
  const [duplicateEntriesMessage, setDuplicateEntriesMessage] = useState(false);
  // enable/disable button for duplicate entries check
  const [duplicateEntriesCheck, setDuplicateEntriesCheck] = useState(false);
  // duplicate entries count
  const [duplicateEntries, setDuplicateEntires] = useState([]);
  // to store unique entries count
  const [uniqueEntriesCount, setUniqueEntriesCount] = useState("");
  const [open, setOpen] = useState(false);
  // state to set the ref if exists modal.
  const [refIdExistsPopUp, setRefIdExistsPopUp] = useState(false);
  // to enable/ disable download shape file button.
  const [enableShapeButton, setEnableShapeButton] = useState(true);

  // use ref to focus the input field
  const inputFocus = useRef(null);
  let dispatch = useDispatch();

  useEffect(() => {
    if (mapPolygonData && mapPolygonData.length !== 0) {
      let res = [...mapPolygonData];
      res &&
        res.forEach((item) => {
          item.datavalue[item.datavalue.id] = !checkAll;
        });
      setSelectedSearchResult(res);
      setResult(res);
    } else if (mapPolygonData.length === 0) {
      setResult([]);
    }
  }, [mapPolygonData]);

  useEffect(() => {
    setMapPolyData(result);
    uncheckedSearchResult();
  }, [result]);

  useEffect(() => {
    if (selectedSearchResult && selectedSearchResult.length !== 0) {
      let res = result.filter(
        (b) =>
          !selectedSearchResult.some((a) => a.datavalue.id === b.datavalue.id),
      );
      setSelectedData(selectedSearchResult);
      setMapPolyData(res);
    } else {
      setSelectedData(selectedSearchResult);
      setMapPolyData(result);
    }
  }, [selectedSearchResult]);

  useEffect(() => {
    if (selectedBufferResult && selectedBufferResult.length !== 0) {
      let res = bufferResult.filter(
        (b) =>
          !selectedBufferResult.some((a) => a.datavalue.id === b.datavalue.id),
      );
      setSelectedBufferData(selectedBufferResult);
      setBufferZone(res);
    } else {
      setSelectedBufferData(selectedBufferResult);
      setBufferZone(bufferResult);
    }
  }, [selectedBufferResult]);

  useEffect(() => {
    if (bufferResult && bufferResult.length !== 0) {
      let uncheckedItems = bufferResult.filter(
        (item) => !item.datavalue[item.datavalue.id],
      );
      //setSelectedBufferResult(bufferResult);
      setBufferCheckAll(uncheckedItems.length === 0 ? true : false);
    } else {
      setBufferCheckAll(false);
    }
  }, [bufferResult]);

  const onCheckAll = (e) => {
    setCheckAll(e.target.checked);
    let res = [...result];
    res &&
      res.forEach((item) => {
        item.datavalue[item.datavalue.id] = e.target.checked;
      });
    if (e.target.checked) {
      setSelectedSearchResult(res);
    } else {
      setSelectedSearchResult([]);
      setMapPolyData(result);
    }
  };

  const onBufferAllCheck = (e) => {
    setBufferCheckAll(e.target.checked);
    let res = [...bufferResult];
    res &&
      res.forEach((item) => {
        item.datavalue[item.datavalue.id] = e.target.checked;
      });
    if (e.target.checked) {
      setSelectedBufferResult(res);
    } else {
      setSelectedBufferResult([]);
      setBufferZone(bufferResult);
    }
  };

  const onRemoveBufferResult = (id, value) => {
    let tempArr = [...bufferResult];
    let val = tempArr.splice(id, 1);
    setBufferResult(tempArr);
    setBufferZone(tempArr);
    //when selected checkbox removeing
    if (selectedBufferResult && selectedBufferResult.length !== 0) {
      let selected = [...selectedBufferResult];
      setSelectedBufferResult(
        selected.filter((type) => type.datavalue.id !== value.datavalue.id),
      );
    }
    let tempResult = [...result];
    val.map((item) => tempResult.push(item));
    setResult(tempResult);
    //checking removing object whether selected or not
    let arr = [];
    tempResult.forEach((item) => {
      if (item.datavalue[item.datavalue.id] === true) {
        arr.push(item);
      }
    });
    setSelectedSearchResult(arr);
  };

  const uncheckedSearchResult = () => {
    if (result && result.length !== 0) {
      let uncheckedItems = result.filter(
        (item) => !item.datavalue[item.datavalue.id],
      );
      setCheckAll(uncheckedItems.length === 0 ? true : false);
    } else {
      setCheckAll(false);
    }
  };

  const onChangeHandler = (e, value) => {
    //Select and unselect checkbox code
    let res = [...result];
    res.forEach((item) => {
      if (item.datavalue.id === value.datavalue.id) {
        item.datavalue[value.datavalue.id] = e.target.checked;
      }
    });
    setResult(res);

    //checking unselect checkbox
    uncheckedSearchResult();
    //select and unselect objects code
    let arr = [...selectedSearchResult];
    if (e.target.checked) {
      arr.push(value);
      setSelectedSearchResult(arr);
    } else {
      setSelectedSearchResult(
        arr.filter((type) => type.datavalue.id !== value.datavalue.id),
      );
    }
  };

  const onChangehandlerBuffer = (e, value) => {
    let res = [...bufferResult];
    res.forEach((item) => {
      if (item.datavalue.id === value.datavalue.id) {
        item.datavalue[value.datavalue.id] = e.target.checked;
      }
    });
    setBufferResult(res);

    let arr = [...selectedBufferResult];
    if (e.target.checked) {
      arr.push(value);
      setSelectedBufferResult(arr);
    } else {
      setSelectedBufferResult(
        arr.filter((type) => type.datavalue.id !== value.datavalue.id),
      );
    }
  };

  const moveToBuffer = () => {
    if (result.length !== 0) {
      setSelectedSearchResult([]);
    }
    let tempArr = [];
    let arr = [...bufferResult];
    selectedSearchResult.forEach((arrData) => {
      bufferResult.forEach((innerArrData) => {
        if (innerArrData.datavalue.id === arrData.datavalue.id) {
          tempArr.push(innerArrData);
          setDuplicateEntriesMessage(true);
          setDuplicateEntriesCheck(true);
        }
      });
    });
    const storeTotalUniqueCount = selectedSearchResult.length - tempArr.length;
    setDuplicateEntires(tempArr);
    setUniqueEntriesCount(storeTotalUniqueCount);
    selectedSearchResult.forEach((type) => arr.push(type));
    // filter out the ids base on the index
    const filteredDuplicates = arr.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.datavalue.id === value.datavalue.id),
    );
    let res = result.filter(
      (b) =>
        !selectedSearchResult.some((a) => a.datavalue.id === b.datavalue.id),
    );
    setResult(res);
    setSelectedBufferResult(filteredDuplicates);
    setBufferResult(filteredDuplicates);
    setBufferZone(filteredDuplicates);
    setDuplicateEntriesCheck(false);
    setEnableShapeButton(false);
  };

  const onClearAll = () => {
    let tempResult = [...result];
    bufferResult.forEach((item) => tempResult.push(item));
    let arr = [];
    tempResult.forEach((item) => {
      if (item.datavalue[item.datavalue.id] === true) {
        arr.push(item);
      }
    });
    setSelectedSearchResult(arr);
    setResult(tempResult);
    setBufferZone([]);
    setBufferResult([]);
    setSelectedBufferResult([]);
    // setSelectedBufferData([]);
    dispatch({
      type: DISABLE_SELECTPICKER,
      payLoad: {
        disableState: true,
      },
    });
  };

  // function to handle reference id input element
  const handleReferenceId = (e) => {
    if (e.length == 21) {
      return false;
    }
    setStoreRefId(e);
    setErrorMessage(false);
  };

  // function to handle the ref if api call on click of request report button.
  const handleRefIdApi = () => {
    _getStorageValue().then(() => {
      checkExistingRefId(
        storeRefId,
        successRefIdCallBack,
        failureRefIdCallBack,
      );
    });
    if (storeRefId.length < 1) {
      setErrorMessage(true);
      setTimeout(() => {
        inputFocus.current.focus();
      }, 200);
    } else {
      return null;
    }
  };

  const successRefIdCallBack = (response) => {
    setRefIdExists(response.length);
    if (storeRefId.length < 1) {
      setErrorMessage(true);
      setTimeout(() => {
        inputFocus.current.focus();
      }, 200);
    } else {
      if (response.length !== 0) {
        setRefIdExistsPopUp(true);
      } else {
        setModalIsOpen(true);
      }
    }
  };

  const failureRefIdCallBack = (response) => {
    console.log(response, "failure ref id api");
  };

  const closePopUp = () => {
    setModalIsOpen(false);
    setTimeout(() => {
      inputFocus.current.focus();
    }, 1000);
  };

  // close the pop up if reference id exists and focus on the input field
  const refIdExistsClosePopUp = () => {
    setRefIdExistsPopUp(false);
    setTimeout(() => {
      inputFocus.current.focus();
    }, 1000);
  };

  //Tooltip
  const defaultTheme = createTheme();
  const theme1 = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "10px",
          color: "white",
          backgroundColor: "grey",
        },
      },
    },
  });
  const TableToolTip = withStyles({
    tooltip: {
      fontSize: "12px",
      color: "white",
      width: "250px",
      backgroundColor: "grey",
    },
  })(Tooltip);

  const closeDupEntryCheckPopUP = () => {
    let res = [...mapPolygonData];
    setDuplicateEntriesMessage(false);
    // setOpen(true);
    setSelectedSearchResult(res);
  };

  // render table header checkbox component.
  function renderCheckBoxComponent() {
    return (
      <Checkbox
        {...label}
        style={{
          padding: 0,
          display: titles.length === 0 && "flex",
          justifyContent: titles.length === 0 && "flex-start",
          paddingLeft: titles.length === 0 && "2rem",
        }}
        sx={{
          color: "#828282",
          "&.Mui-checked": {
            color: "#262261",
          },
        }}
        checked={checkAll}
        size="small"
        onChange={(e) => onCheckAll(e)}
      />
    );
  }

  const LEVELS = [
    "levelOne",
    "levelTwo",
    "levelThree",
    "levelFour",
    "levelFive",
    "levelSeven",
    "levelSix",
    "levelEight",
    "levelNine",
    "levelTen",
    "levelEleven",
    "levelTwelve",
    "levelThirteen",
    "levelFourteen",
    "levelFifteen",
  ];

  const isCurrentLevelAccessTrue = (element) =>
    element.currentLevelAccess === true;
  const index = storeDropdowncomponents.findIndex(isCurrentLevelAccessTrue);
  const levels = LEVELS.splice(index);

  const storeNewFilteredLevels = [];
  var titles = [];

  const numWords = [
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
  ];

  result.map((item) => {
    const key = Object.keys(item.datavalue).filter((keyItem) => {
      return keyItem.includes("level");
    });
    // sort the array using a compare function
    const sortedKeys = key.sort((a, b) => {
      // compare the index of numerical word of a and b in numWords array
      // by removing 'level' prefix from a and b
      return (
        numWords.indexOf(a.replace("level", "")) -
        numWords.indexOf(b.replace("level", ""))
      );
    });

    const keys = [];
    sortedKeys.map((keyValue) => {
      if (levels.includes(keyValue)) {
        keys.push(item.datavalue[keyValue]);
        if (!titles.includes(item.datavalue[keyValue].label)) {
          titles.push(item.datavalue[keyValue].label);
        }
      }
      return item.datavalue[keyValue];
    });
    storeNewFilteredLevels.push(keys);
  });

  // render dynamic table header function
  const renderTableHeaders = () => {
    if (storeTableHeaders === true) {
      return (
        <>
          <tr>
            <td style={{ paddingRight: "10px" }}>
              {renderCheckBoxComponent()}
            </td>
            {titles.map((getFilteredItems, index) => {
              return (
                <td key={index} style={{ textTransform: "capitalize" }}>
                  {getFilteredItems}
                </td>
              );
            })}
          </tr>
        </>
      );
    }
  };

  // function to handle the shape file donwload functionality.

  const handleDownloadShapeFile = () => {
    const storeRegionIds = [];
    selectedBufferResult.forEach((fetchRegionIds) => {
      storeRegionIds.push(fetchRegionIds.datavalue.id);
    });
    const requestPayload = {
      regionIds: storeRegionIds,
    };
    _getStorageValue(USER_ID).then((user_id) => {
      downloadShapeFile(
        requestPayload,
        successDownloadShapeFileCallback,
        failureDownloadShapeFileCallback,
      );
    });

    const successDownloadShapeFileCallback = (response) => {
      // Create a Blob from the binary response
      const blob = new Blob([response], { type: "application/zip" });

      // Generate the dynamic file name with date and time using Moment.js
      const currentDate = moment().format("YYYY-MM-DD_HH-mm-ss");
      const fileName = `shapefile_${currentDate}.zip`;

      // Create a temporary URL for the Blob
      const downloadUrl = URL.createObjectURL(blob);

      // Create a temporary link to initiate the download
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = fileName;

      // Programmatically click the link to initiate the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Clean up the temporary URL
      URL.revokeObjectURL(downloadUrl);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    };

    const failureDownloadShapeFileCallback = (error) => {
      console.log(error, "===== error shape file");
    };
  };

  return (
    <>
      <div className="top">
        <div className="box">
          <p className="box-title">
            1. SEARCH RESULT ({result.length})
            <MuiThemeProvider theme={theme1}>
              <TableToolTip title="Search results from location filter can be found in this section.">
                <img src={information} alt="icon"></img>
              </TableToolTip>
            </MuiThemeProvider>
          </p>
        </div>
        <table className="custom-map-table">
          <thead>{renderTableHeaders()}</thead>
          <tbody className="custom-map-table-tbody">
            {result && result.length !== 0 ? (
              result.map((getResults, id) => {
                return (
                  <tr key={id}>
                    <td>
                      <Checkbox
                        {...label}
                        style={{
                          padding: 0,
                          height: 15,
                          width: 15,
                        }}
                        sx={{
                          color: "#828282",
                          "&.Mui-checked": {
                            color:
                              getResults.coordinates === null
                                ? "#FF8A00"
                                : "#d10000",
                          },
                        }}
                        checked={
                          getResults.datavalue[getResults.datavalue.id] !==
                          undefined
                            ? getResults.datavalue[getResults.datavalue.id]
                            : false
                        }
                        size="small"
                        onChange={(e) => onChangeHandler(e, getResults, id)}
                      />
                    </td>
                    {storeNewFilteredLevels[id].map((getDataValue, index) => {
                      return (
                        <td key={index}>
                          {getDataValue.value === null
                            ? "-"
                            : getDataValue.value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="empty">
                <td colSpan={4}>
                  Search to view list of the land boundaries to select from.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="button-group custom-button-group">
          <button
            className={
              result.length == 0 ? "btn btn-buffer-disabled" : "btn btn-buffer"
            }
            disabled={!selectedSearchResult.length}
            onClick={
              result.length == 0
                ? null
                : () => {
                    ReactGA.event({
                      category: "click",
                      action: "Move_to_Buffer_Zone",
                    });
                    moveToBuffer();
                  }
            }
          >
            Move to Buffer zone
          </button>
        </div>
      </div>
      <DupEntryCheckPopUP
        closeDupEntryCheckPopUP={closeDupEntryCheckPopUP}
        duplicateEntriesMessage={duplicateEntriesMessage}
        duplicateEntries={duplicateEntries}
        uniqueEntriesCount={uniqueEntriesCount}
        open={open}
      />
      <Bufferzone
        bufferResult={bufferResult}
        onRemoveBufferResult={onRemoveBufferResult}
        onBufferAllCheck={onBufferAllCheck}
        onClearAll={onClearAll}
        onChangehandlerBuffer={onChangehandlerBuffer}
        selectedBufferResult={selectedBufferResult}
        bufferCheckAll={bufferCheckAll}
        handleRefIdApi={handleRefIdApi}
        storeRefId={storeRefId}
        handleReferenceId={handleReferenceId}
        refIdExists={refIdExists}
        modalIsOpen={modalIsOpen}
        closePopUp={closePopUp}
        errorMessage={errorMessage}
        inputFocus={inputFocus}
        result={result}
        storeCurrentlevel={storeCurrentlevel}
        storeTableHeaders={storeTableHeaders}
        setModalIsOpen={setModalIsOpen}
        mapPolygonData={mapPolygonData}
        filter={filter}
        storeDropdowncomponents={storeDropdowncomponents}
        refIdExistsPopUp={refIdExistsPopUp}
        refIdExistsClosePopUp={refIdExistsClosePopUp}
        enableShapeButton={enableShapeButton}
        handleDownloadShapeFile={handleDownloadShapeFile}
      />
    </>
  );
};

export default MapTable;
