import React from "react";
import Button from "react-bootstrap/Button";
import "./button.css";

const CommonButton = (props) => {
  return (
    <Button
      variant="contained"
      onClick={!props.btnLoader ? props.btnCallBack : undefined}
      disabled={
        props.btnLoader || props.btnDisable || props.disableSearchButton
      }
      className="loader-button"
    >
      {props.btnLoader ? (
        <div className="loadbtn" data-testid="loader">
          <div className="loader">
            <div className="circle" id="a"></div>
            <div className="circle" id="b"></div>
            <div className="circle" id="c"></div>
          </div>
        </div>
      ) : (
        <p>{props.btnName}</p>
      )}
    </Button>
  );
};

export default CommonButton;
