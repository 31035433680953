import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import state from "../src/reducer/index";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

Sentry.init({
  dsn: "https://193fe44a6444dd7d5bfe0bac3ddf1a40@o4506252999000064.ingest.sentry.io/4506258056282112",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/sage.satsure\.co/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.05, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
console.log("Current Environment:", process.env.REACT_APP_FE_VERSION);
if (process.env.REACT_APP_FE_VERSION === "production") {
  console.log("Initializing Google Analytics in production mode.");
  ReactGA.initialize("G-TDPVJ6FRS3");
  ReactGA.send({
    hitType: "pageview",
    page: "/index.html",
    title: "Test Title",
  });
} else {
  console.log("Google Analytics is disabled in this environment.");
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={state}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
