import React from "react";
import Paper from "@mui/material/Paper";
import { _getStorageValue, getCompanyConfigs } from "../../common/localStorage";
import ReactGA from "react-ga4";

export default function Reportdropdown(props) {
  const companyConfigss = JSON.parse(getCompanyConfigs());
  const reportTypeAccess = companyConfigss.download_report_type;

  const reportTypes = {
    brief: {
      label: "Brief Report(2MB)",
      handler: props.handleBriefReportDownload,
    },
    full: {
      label: "Detailed Report",
      handler: props.handleDetailedReportDownload,
    },
    compact: {
      label: "Compact Report",
      handler: props.handleCompactReportDownload,
    },
  };

  return (
    <div
      className="down"
      style={{
        display: "none",
        position: "absolute",
        right: "28.5%",
        zIndex: 9999,
      }}
      ref={props.reportDropdown}
    >
      {props.dropdownstate === 1 && (
        <Paper
          className="dropdown"
          id={"_dropdownid"}
          elevation={2}
          sx={{
            displayPrint: "none",
            zIndex: 10000,
            position: "relative",
          }}
        >
          <div className="dropdown-content">
            <ul>
              {Object.keys(reportTypeAccess).map((key) =>
                reportTypeAccess[key] ? (
                  <li key={key} className="dropdown-outside-click">
                    <a
                      onClick={(e) => {
                        ReactGA.event({
                          category: "click",
                          action: reportTypes[key].label + "_count",
                        });
                        reportTypes[key].handler(e, props.tableData);
                      }}
                    >
                      {reportTypes[key].label}
                    </a>
                  </li>
                ) : null,
              )}
            </ul>
          </div>
        </Paper>
      )}
    </div>
  );
}
