import React, { useState } from "react";
// plugin
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ReactGA from "react-ga4";
// style
import styles from "./styles.module.css";

export default function Raisequery(props) {
  const queries = [
    "Blank Report Generated",
    "Delayed Report Generation",
    "False Crop Identified",
  ];
  const customStyles = {
    content: {
      position: "absolute",
      top: "40%",
      left: "38%",
      right: "90%",
      bottom: "0",
      marginRight: "-70%",
      marginLeft: "10%",
      borderRadius: "10px",
      transform: "translate(-40%, -40%)",
      width: "40%",
      height: "450px",
      overflow: "hidden",
    },
    overlay: {
      zIndex: 5000,
      background: "transparent",
    },
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div>
      <Modal
        isOpen={props.modalIsOpen}
        style={customStyles}
        transparent={true}
        ariaHideApp={false}
      >
        <div className={styles.modalContent}>
          <h1>RAISE QUERY</h1>
          <hr />
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Reference ID"
              variant="outlined"
              disabled={props.disableTextField}
              defaultValue={props.tableRefId}
              inputProps={{ readOnly: true }}
            />
          </Box>
          <FormControl style={{ margin: "15px 0" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {queries.map((elem, index) => {
                return (
                  <FormControlLabel
                    value={elem}
                    control={<Radio />}
                    label={elem}
                    key={index}
                    onChange={() => props.handleCheckboxState(elem)}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {props.checkBoxError && (
            <p className={styles.checkboxError}>Please select a query type</p>
          )}
          {props.successMessage && (
            <p className={styles.success}>Query submitted successfully</p>
          )}
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextareaAutosize
              maxRows={100}
              aria-label="Add Comment"
              placeholder="Add Comment"
              id="fullWidth"
              style={{ width: 500, height: "80px" }}
              className={styles.customTextArea}
              onBlur={(e) => props.handleComment(e.target.value)}
            />
          </Box>
          <div className={styles.buttonWrapper}>
            <Button
              variant="contained"
              className={
                props.activeButton ? styles.addSubmitBtn : "disabled-button"
              }
              onClick={() => {
                if (props.activeButton) {
                  ReactGA.event({
                    category: "click",
                    action: "Raise_query_submit",
                  });
                  props.handleFormSubmit();
                }
              }}
              disabled={!props.activeButton}
              style={{
                padding: "5px 20px",
                borderRadius: "5px",
              }}
            >
              Submit
            </Button>

            <Button
              variant="contained"
              className="add-button"
              onClick={props.closePopUp}
              style={{ border: "1px solid rgba(27, 69, 173, 1) !important" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
